import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <body>
        <div className="grid h-screen place-items-center">
          <a href="mailto:jv@twenty4.dev">
            <img src={process.env.PUBLIC_URL + '/img/Twenty4.svg'} width={'350px'} alt='Logo of Twent4' />
          </a>
        </div>
      </body>
    </div>
  );
}

export default App;
